<template>
  <div class="settlein">
    <page-nav title="商家入驻" />
    <div class="content">
      <h2 class="section-title">入驻指南</h2>
      <div class="advantage-list">
        <div class="list-item item-leader">
          <p class="item-number"><span>01</span></p>
          <div class="item-text fadeInUp">
            <p class="title">行业领先</p>
            <p class="t-eng">INDUSTRY LEADING</p>
            <p class="line"></p>
            <p>国内首创，行业领先</p>
            <p>创始初期即与拥有千万级活跃用户</p>
            <p>垂直综合平台-成为合作伙伴</p>
          </div>
        </div>
        <div class="list-item item-user shake shake-slow">
          <p class="item-number"><span>02</span></p>
          <div class="item-text fadeInUp">
            <p class="title">庞大的用户群</p>
            <p class="t-eng">LARGE USER BASE</p>
            <p class="line"></p>
            <p>庞大的用户群</p>
            <p>线上线下媒体深度合作万千网友的信赖</p>
            <p>深耕以销售用户为基础的综合市场</p>
          </div>
        </div>
        <div class="list-item item-team shake shake-slow">
          <p class="item-number"><span>03</span></p>
          <div class="item-text fadeInUp">
            <p class="title">专业团队</p>
            <p class="t-eng">PROFESSIONAL TEAM</p>
            <p class="line"></p>
            <p>专业店铺管家</p>
            <p>专业招商团队，一对一服务</p>
            <p>清晰申请流程，高效的审核制度</p>
          </div>
        </div>
        <div class="list-item item-cost shake shake-slow">
          <p class="item-number"><span>04</span></p>
          <div class="item-text fadeInUp">
            <p class="title">零风险低成本</p>
            <p class="t-eng">ZERO RISK AND LOW COST</p>
            <p class="line"></p>
            <p>0佣金，助你实现0库存</p>
            <p>暂不收取商品销售佣金</p>
            <p>独特销售模式为你实现0库存</p>
          </div>
        </div>
      </div>
      <h2 class="section-title">入驻流程</h2>
      <div class="steps">
        <img src="../assets/ruzhu.png" />
        <div class="step-info">
          <div class="t">
            <p class="title">注册本站账号</p>
            <p>在网站登录页面点击“立即注册”按钮进行注册，成为本站会员</p>
          </div>
          <div class="bg-icon"></div>
          <div class="t">
            <p class="title">提交申请</p>
            <p>进入“商家入驻”页面，点击底部“立即入驻”即可申请入驻</p>
          </div>
          <div class="bg-icon"></div>
          <div class="t">
            <p class="title">等候审核</p>
            <p>本站会在3个工作日内审核您的入驻申请</p>
          </div>
          <div class="bg-icon"></div>
          <div class="t">
            <p class="title">入驻成功</p>
            <p>入驻成功后您即可发布自己的产品</p>
          </div>
        </div>
      </div>
    </div>
    <div class="fix-btn">
      <van-button type="info" round @click="onSettlein">立即入驻</van-button>
    </div>
  </div>
</template>

<script>
import User from '../common/user';
import Fetch from '../common/fetch';
import { UserOpenStore } from '../common/interface';
import { Toast } from 'vant';
import PageNav from '../components/PageNav.vue';

export default {
  name: 'Settlein',
  components: { PageNav },
  mounted() {
    window.scrollTo(0,0);
  },
  methods: {
    onSettlein() {
      const user = User.getInfo();
      if (user) {
        if (user.storeId) { // 已有店铺
          Toast.fail('您已是入驻商户');       
        } else {
          Fetch.post(UserOpenStore).then((res) => {
            Toast.success('入驻成功');
            User.modifyInfo({
              storeId: res,
            });
          }).catch(err => {
            Toast(err.msg || '请求失败');
          })
        }
      } else {
        this.$router.push('/login');
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.settlein { background: #fff; padding-bottom: 50px; }
.settlein .section-title { text-align: center; font-weight: bold; font-size: 15px;  margin: 10px 0; }
.advantage-list { padding: 0 16px 30px; }
.list-item { position: relative; overflow: hidden; margin: 10px 0; height:240px; border-radius: 10px; }
.item-leader { background-color:#35d198; }
.item-user { background-color:#ff907e; }
.item-team { background-color:#a68aff; }
.item-cost { background-color:#ffc740; }
.item-number span { display: block; margin:10px; width:30px; height: 30px; border-radius: 50%; text-align: center; line-height: 30px; font-size: 14px; font-weight: bold; }
.item-leader .item-number span { color: #59E5B2; background-color: #2aba85; }
.item-user .item-number span { color: #FFAAAA; background-color: #fc6c55; }
.item-team .item-number span { color: #BBA5FF; background-color: #9270ff; }
.item-cost .item-number span { color: #FFD170; background-color: #eeb12e; }
.item-text { color:#fff; text-align: center; font-size: 14px; }
.item-text .title { font-size: 24px; padding: 0 0 8px; font-weight: bold; }
.item-text .t-eng { font-size: 14px; font-weight: bold; }
.item-text .line { height: 1px; background-color:#cecece; opacity: 0.5; width: 80%; margin: 20px auto; }

.settlein .steps {
  padding-bottom: 30px;
}

.settlein .fix-btn {
  position: sticky;
  bottom: 50px;
  text-align: center;
}

.step-info { margin: 15px 0; }
.steps .t { margin:0 10px; padding: 15px 10px; background-color: #01cbbb; border-radius: 8px; color:#fff; text-align: center; font-size: 12px; }
.t .title { font-size: 15px; font-weight: bold; margin-bottom: 1em; }

.steps .bg-icon { width: 20px; height: 20px; margin:10px auto; background: url('../assets/step-arrow-down.png') center no-repeat; background-size: contain; }

.fadeInUp {
  animation: fadeInUp;
  animation-duration: 1s;
}

@keyframes fadeInUp{
  0% {
      opacity: 0;
      transform: translateY(50px);
  }
  100% {
      opacity: 1;
      transform: translateY(0);
  }
}
</style>